import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import img1 from '../../../images/services/main-floor-footing-elevation-img1.jpg';
import img2 from '../../../images/services/main-floor-footing-elevation-img2.jpg';
import img3 from '../../../images/services/main-floor-footing-elevation-img3.jpg';

const MainFloorAndFootingElevationSurveys = () => (
  <Layout
    title="Main Floor, Footing Elevation, & Finished Floor Elevation Surveys in Calgary & Area"
    description="Looking for reliable finished floor elevation surveys? Axiom Geomatics provides expert services in Calgary to ensure compliance and accurate construction results."
    keywords="main floor elevation survey, verification, certification, footing check"
  >
    <main>
      <h1>Main Floor & Footing Elevation Surveys Calgary & Area</h1>

      <div className="text-center my-4">
        <img src={img1} alt="" width={800} height={350} />
      </div>

      <p>
        A Main Floor Elevation Survey measures the height of the main floor of the building under
        construction to ensure that it has been built to the correct height. This survey should be
        completed as the floor joists and main floor sheeting are being put down.
      </p>
      <p>
        At this stage, verifying the finished floor elevation helps ensure compliance with municipal
        height regulations.
      </p>
      <p>
        A Footing Elevation Survey measures the height of the footing, the concrete outline upon
        which basement walls are poured. This survey is completed very early in the construction
        process.
      </p>
      <p>
        Depending upon the age of the neighbourhood, the local municipal bylaws and whether this is
        new construction or a renovation, either the Main Floor Survey or the Footing Elevation
        Survey will be required, not both.
      </p>
      <p>
        These surveys are particularly important as a structure built too low may have sewage issues
        (e.g., it won't drain properly). Structures that are too high look out of place relative to
        their neighbours and may violate the maximum height allowed. Each municipality sets its own
        tolerance for how high or low a structure may be relative to the approved Development Permit
        and <Link to="/services/construction-services/plot-plans">Plot Plan</Link>.
      </p>
      <p>
        When completed, our team at Axiom Geomatics will provide a Main Floor Elevation Certificate,
        confirming that the finished floor elevation meets the required standards.
      </p>
      <h2>
        <strong>Ensuring Accurate Elevations for Compliance </strong>
      </h2>

      <p>
        Building a structure that meets municipal height requirements affects drainage, stability,
        and long-term usability. A finished floor elevation that is too high or low can cause a
        whole host of compliance issues, leading to costly corrections (not to mention a great deal
        of frustration).
      </p>
      <p>
        That’s why elevation surveys are so important in construction. They provide builders,
        engineers, and property owners with precise data to confirm that all elevations meet the
        approved plans.
      </p>
      <p>
        Essentially, <strong>finished main floor elevation</strong> is the reference point for the
        height of a building’s <em>primary living level</em>, measured relative to all known
        benchmarks. If the elevation is incorrect, for example, it can disrupt utility connections,
        create water drainage problems, and impact neighbourhood uniformity. As you are probably
        aware, municipalities enforce strict regulations on maximum and minimum heights to prevent
        these issues.
      </p>
      <p>
        An elevation survey helps ensure compliance and greatly reduces the risk of project delays
        for both new builds and renovations. If you are overseeing a residential, commercial, or
        industrial development, verifying elevation accuracy early in the process will help avoid
        unwelcome surprises later on.
      </p>

      <div className="text-center my-4">
        <img src={img2} alt="" width={800} height={450} />
      </div>

      <h2>
        <strong>Elevation Surveys in Construction</strong>
      </h2>

      <h3>
        <strong>1. Preventing Drainage and Utility Issues</strong>
      </h3>

      <p>
        One of the biggest risks of an incorrectly finished floor elevation is improper drainage. If
        a structure is built too low, water probably won’t drain away correctly. This leads to
        pooling, flooding, and (in the worst case) even foundation damage. By the opposite token, if
        the building is too high, it may not meet perfectly with existing sewer and utility
        connections, once again leading to costly modifications.
      </p>
      <h4>
        <strong>Common utility issues related to incorrect elevation:</strong>
      </h4>

      <ul>
        <li>Sewer line backflow due to improper gravity drainage</li>

        <li>Misalignment with gas and water mains</li>

        <li>Electrical conduit placement conflicts</li>

        <li>Stormwater runoff causing damage to neighbouring properties</li>
      </ul>
      <p>
        A professional elevation survey helps negate these problems by confirming that the footing
        and floor heights meet pre-approved designs.
      </p>
      <h3>
        <strong>2. Meeting Municipal Zoning and Permit Requirements in Calgary</strong>
      </h3>

      <p>
        Municipalities have strict zoning laws that regulate maximum building heights. Although
        these rules are challenging to comply with, they are in place for good reason, to help new
        developments blend with existing structures.
      </p>
      <p>
        Failing to adhere to these height restrictions usually results in permit violations, fines,
        and mandatory modifications - the latter of which can be <em>incredibly</em> costly
        post-construction.
      </p>
      <h4>
        <strong>An elevation survey helps builders and property owners:</strong>
      </h4>

      <ul>
        <li>Verify that a finished floor elevation meets approved plot plans</li>

        <li>Avoid the risk of non-compliance with zoning bylaws</li>

        <li>Complete documentation for inspections and approvals</li>
      </ul>
      <p>
        <strong>
          Builders and contractors who get elevation surveys conducted will always reduce the
          chances of legal disputes and costly reconstruction.{' '}
        </strong>
      </p>
      <h3>
        <strong>3. Ensuring Structural Stability and Long-Term Viability</strong>
      </h3>

      <p>
        Aside from compliance, correct elevation encourages greater stability in a structure. When a
        building’s foundation and finished floor elevation are accurately set, it helps maintain:
      </p>
      <ul>
        <li>Proper weight distribution across the foundation</li>

        <li>Reduced risk of structural shifting over time</li>

        <li>Better performance of insulation and moisture barriers</li>

        <li>Easier integration of additional structures or extensions</li>
      </ul>
      <p>
        An incorrect elevation can introduce long-term complications that are far more important
        than simple aesthetic concerns. By confirming height accuracy early in the construction
        process, you can protect the integrity of the entire project - not to mention the budget.
      </p>

      <div className="text-center my-4">
        <img src={img3} alt="" width={800} height={450} />
      </div>

      <h2>
        <strong>The Importance of Accurate Elevation Data</strong>
      </h2>

      <p>
        A precise finished floor elevation is incredibly important for drainage, structural
        stability, and red tape such as regulatory compliance. Elevation surveys gift builders with
        important, useful data to prevent costly mistakes and project delays.
      </p>
      <p>
        Our experienced team at <Link to="/about-us">Axiom Geomatics</Link> ensures these builders,
        developers, and property owners will receive accurate elevation measurements that meet
        municipal standards in Calgary.
      </p>
      <p>
        Work with experienced surveyors such as ourselves, and you will avoid unnecessary setbacks
        and keep your project on track. Investing in a professional elevation survey is a wise step
        toward a much smoother construction process.
      </p>
      <p>
        Ready to confirm your finished floor elevation?{' '}
        <Link to="/order-a-development-permit-survey">Contact Axiom Geomatics</Link>, and we will
        make the process far easier, quicker, and more accurate from the start.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#too-high">What happens if my main floor is too high?</Link>
        </li>
        <li>
          <Link to="#too-low">What happens if my main floor is too low?</Link>
        </li>
        <li>
          <Link to="#really-need">Do I need a Main Floor or Footing Elevation Survey?</Link>
        </li>
        <li>
          <Link to="#importance-of">
            What is the importance of a finished floor elevation in construction?
          </Link>
        </li>
      </ul>

      <h3 id="too-high">What happens if my main floor is too high?</h3>
      <p>
        If the structure exceeds the maximum height, the roof may need to be re-architected to be
        flatter. In most cases, the builder is able to remediate the existing structure so it is
        within acceptable tolerance levels. However, this varies on a case-by-case basis.
      </p>

      <h3 id="too-low">What happens if my main floor is too low?</h3>
      <p>
        Too low may cause conflicts with water/sewer/gas/electrical lines that go under the
        foundation. The builder is able to remediate the existing structure so it is within
        acceptable tolerance levels, although this varies case by case.
      </p>

      <h3 id="really-need">Do I need a Main Floor or Footing Elevation Survey?</h3>
      <p>This depends on which municipality you live in. Check locally for precise information.</p>
      <h3 id="importance-of">
        What is the importance of a finished floor elevation in construction?
      </h3>
      <p>
        A properly finished floor elevation encourages proper drainage, structural stability, and
        firm compliance with local municipal regulations. If it's too low, water may pool around the
        foundation, and if it's too high, it may exceed zoning limits. Verifying the finished floor
        elevation early usually helps avoid expensive corrections and a smoother construction
        process.
      </p>
    </main>
  </Layout>
);

export default MainFloorAndFootingElevationSurveys;
